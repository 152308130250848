import gsap from "gsap";
import imagesLoaded from "imagesloaded";
import Scrollbar, { ScrollbarPlugin, smoothScrollCapture} from 'smooth-scrollbar';

class DisableScrollPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll';

  static defaultOptions = {
    direction: '',
  };

  transformDelta(delta) {
    if (this.options.direction) {
      delta[this.options.direction] = 0;
    }

    return { ...delta };
  }
}

// load the plugin
Scrollbar.use(DisableScrollPlugin);


class AnchorPlugin extends ScrollbarPlugin {
  static pluginName = 'anchor';

  onHashChange = () => {
    this.jumpToHash(window.location.hash);
  };

  onClick = (event) => {
    const { target } = event;

    if (target.tagName !== 'A') {
      return;
    }

    const hash = target.getAttribute('href');

    if (!hash || hash.charAt(0) !== '#') {
      return;
    }

    this.jumpToHash(hash);
  };

  jumpToHash = (hash) => {
    const { scrollbar } = this;

    if (!hash) {
      return;
    }    

    // reset scrollTop
    scrollbar.containerEl.scrollTop = 0;

    scrollbar.scrollIntoView(document.querySelector(hash));
  };

  onInit() {
    this.jumpToHash(window.location.hash);

    window.addEventListener('hashchange', this.onHashChange);

    this.scrollbar.contentEl.addEventListener('click', this.onClick);
  }

  onDestory() {
    window.removeEventListener('hashchange', this.onHashChange);

    this.scrollbar.contentEl.removeEventListener('click', this.onClick);
  }
}

// usage
Scrollbar.use(AnchorPlugin);



const bar = document.querySelector(".loading__bar--inner");
const counter_num = document.querySelector(".loading__counter--number");

let count = 0;

let barInterval=setInterval(() => {
    bar.style.width=count + "%";
    counter_num.innerText=count + "%";
    count++;
    if(count === 101) {
        clearInterval(barInterval);
        gsap.to('.loading__bar' , {
            duration:5,
            rotate: "90deg",
            left: "1000%",
        });
        gsap.to('.loading__text, .loading__counter' , {
            duration:1,
            opacity:0,
        });
        gsap.to('.loading__box' , {
            duration:1.5,
            height:"350px",
            borderRadius: "50%",

        });
        imagesLoaded(document.querySelectorAll('img'),()=>{
            gsap.to('.loading' , {
                delay:2,
                duration:2,
                zIndex:0,
                background: "transparent",
                opacity: 0.5,
            
    
             });
             gsap.to('.loading__svg' , {
                delay:2,
                duration:3,
                rotate: "360deg",
                opacity: 0.5,
             });
    
             gsap.to(".header", {
                duration:1,
                delay:2,
                top: "0",
             });
    
             gsap.to(".socials", {
                duration:1,
                delay:2,
                bottom: "10rem",
             });
             gsap.to(".scrollDown", {
                duration:1,
                delay:3,
                bottom: "3rem",
             });
             setTimeout(() => {
                let options= {
                    damping:0.1,
                    alwaysShowTracks:true,
                    lugins: {
                        disableScroll: {
                          direction: 'x',
                        },
                      },
                 };
                 let pageSmoothScroll=Scrollbar.init(document.body,options);
                 pageSmoothScroll.track.xAxis.element.remove();
             }, 2000);
            
        });
        gsap.to('.loading__svg' , {
            duration:2,
            opacity:0.5 ,
            rotate: "360deg",
        });
        gsap.to('.loading__box' , {
           delay:2,
           border: "none",
        });
     
    }
}, 5);



const questions = [...document.querySelectorAll(".question")];
questions.map((question) => {
    let q_text=question.querySelector("h3");
    q_text.addEventListener("click", () => {
        questions.filter((q) => q !== question).map((q) => q.classList.remove('open'));
        question.classList.toggle("open");
    });
});

